import React, { useEffect } from "react";

interface PopupClipboardProps {
    show: boolean;
    setShow: Function;
}

const PopupClipboard = ({ show, setShow }: PopupClipboardProps) => {

  useEffect(() => {
    if (!show) return;
    setTimeout(() => {
      setShow(false);
    }, 2300);
  }, [setShow, show]);

  return (
    <div className="fixed -bottom-20 w-full justify-center flex mb-5 z-50">
      <p
        className={`text-[#64ffda] px-10 py-5 bg-[#0a192f] rounded-lg border-[#64ffda] border-2 transition-transform ease-in duration-300 transform translate-y-24 ${show} ${
          show && "-translate-y-20"
        }`}
      >
        Email copied to clipboard
      </p>
    </div>
  );
};

export default PopupClipboard;
