import { FormattedMessage } from "react-intl";
import "../../../css/visibilityAnimation.css";

const StrenghtContainer = ({ isVisible }: any) => {
  return (
    <div
      className={`flex flex-col lg:w-[47%] bg-[#122747] p-4 shadow-2xl border border-[#8695aa] rounded-xl w-[92%] lg:min-w-[380px] ${
        isVisible ? "animation_strenght" : "scale-0"
      }`}
    >
      <h2 className="lg:text-4xl text-[#64ffda] w-fit text-xl font-semibold md:font-normal self-center lg:self-start">
        {" "}
        • <FormattedMessage id="strength" />
      </h2>
      <p className="text-[#ccd6f6] lg:text-2xl lg:mt-4 mt-2 text-lg lg:text-start text-justify">
        <FormattedMessage id="strength_text1" />{" "}
        <span className="font-semibold italic">
          <FormattedMessage id="strength_text2" />
        </span>{" "}
        <FormattedMessage id="strength_text3" />
        <br />
        <FormattedMessage id="strength_text4" />
        <br/>
        <FormattedMessage id="strength_text5" />
        
        <span className="font-semibold italic">
          <FormattedMessage id="strength_text6" />
        </span>
        .
      </p>
    </div>
  );
};

export default StrenghtContainer;
