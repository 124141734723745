import { LOCALES } from "./locales";

export const messages = {
  [LOCALES.ENGLISH]: {
    introduction: "Hi my name is",
    job: "I am a full stack web developer",
    Home: "Home",
    About: "About",
    Stacks: "Stacks",
    Projects: "Projects",
    Contact: "Contact",
    cv_button: "My Resume",
    background: "My background",
    background_text:
      "Passionate about the web universe, I began coding at the age of eleven. I pursued computer science studies at",
    background_text2: "for 5 years, earning my engineering degree.",
    background_text3: "Additionally, I spent a year at",
    background_text4:
      "in California, becoming bilingual and furthering my knowledge in computer science.",
    AboutMe: "About Me",
    AboutMe_text1: "Social and lively, I enjoy gaining new ",
    AboutMe_text2: "knowledges and skills",
    AboutMe_text3:
      ". Exploring new cultures through travel fuels my open-mindedness and aids in developing innovative projects.",
    AboutMe_text4:
      "As a sports enthusiast, I particularly enjoy basketball and football.",
    strength: "My strengths",
    strength_text1:
      "Experienced full-stack developer with strong DevOps skills from internships and proficient in",
    strength_text2: "Agile methods",
    strength_text3: "and teamwork.",
    strength_text4:
      " Expanded computer knowledge through courses in CyberSecurity, Database, AI, Finance, and Marketing during Long Beach University stay.",
    strength_text5:
      "Currently involved in a 3-year project with students in different countries, demonstrating my ability to work on complex projects with strict",
    strength_text6: " deadlines",
    internship: "Jobs/Internships",
    months: "months",
    devops_engineer: "DevOps Engineer",
    devops_test: "DevOps & Test Engineer",
    project: "My personnal projects",
    project_subtitle: "Personnal Project",
    Netfake_description:
      "Netfake is a Netflix clone made with Next.JS. Once you are connected, you can watch trailers or add trailers to your watchlist and watch them later.",
    Ecell_description:
      "E-Cell is a FAKE e-commerce plateform where you can buy phones of different brands. You can add items to your cart, check others customers reviews and checkout your order.",
    chesser_description:
      "Chesser is a chess game built with Next.js and javascript. No chess library were used. The game allows two players to play against each other on the same computer.",
    project_button_demo: "Live Demo",
    contact_title: "Get in touch",
    contact_form: "Contact me",
    name: "Name",
    subject: "Subject",
    message: "Type your message here...",
    submit: "Submit",
  },
  [LOCALES.FRENCH]: {
    introduction: "Je m'appelle",
    job: "Je suis développeur full stack",
    Home: "Accueil",
    About: "A propos",
    Stacks: "Stacks",
    Projects: "Projets",
    Contact: "Contact",
    cv_button: "Mon CV",
    background: "Mon parcours",
    background_text:
      "Passionné par l'univers du web, j'ai commencé à coder à l'âge de onze ans. J'ai poursuivi mes études en informatique à",
    background_text2: "pendant 5 ans, obtenant mon diplôme d'ingénieur.",
    background_text3: "De plus, j'ai passé une année à",
    background_text4:
      "en Californie, devenant bilingue et approfondissant mes connaissances en informatique.",
    AboutMe: "A propos de moi",
    AboutMe_text1: "Sociable et dynamique, j'aime acquérir de nouvelles ",
    AboutMe_text2: "compétences et connaissances",
    AboutMe_text3:
      ". Mon attrait pour les voyages me permet de découvrir de nouvelles cultures et cette ouverture d'esprit m'aide à développer des projets innovants.",
    AboutMe_text4:
      "Grand fan de sport, j'aime particulièrement le basket-ball et le football.",
    strength: "Mes forces",
    strength_text1:
      "Développeur full-stack expérimenté avec de solides compétences DevOps, habitué aux ",
    strength_text2: "méthodes agiles",
    strength_text3: "et au travail d'équipe.",
    strength_text4:
      "J'ai suivi des cours de CyberSécurité, Base de Données, IA, Finance et Marketing à l'université de Long Beach.",
    strength_text5:
      "Actuellement impliqué dans un projet de 3 ans avec des étudiants dans différents pays qui démontre ma capacité à travailler sur des projets complexes avec des ",
    strength_text6: "délais stricts",
    internship: "Emplois/Stage",
    months: "mois",
    devops_engineer: "Ingénieur DevOps",
    devops_test: "Ingénieur DevOps & Test",
    project: "Mes projets personnels",
    project_subtitle: "Projet personnel",
    Netfake_description:
      "Netfake est un clone de Netflix fait avec Next.JS, Une fois connecté, vous pouvez regarder des trailers ou les ajouter à votre liste afin de les voir plus tard.",
    Ecell_description:
      "E-Cell est un FAUX site d'e-commerce où on peut acheter des téléphones de différentes marques. Vous pouvez ajouter des objets à votre panier, voir les avis d'autres utilisateurs..",
    chesser_description:
      "Chesser est un jeu d'échec concç grâce à Next.js et Javascript. Aucune librairie n'a été utilisée. Ce jeu permet à deux joueurs de s'affronter sur le même ordinateur.",
    project_button_demo: "Démonstration",
    contact_title: "Mes informations",
    contact_form: "Contactez moi",
    name: "Nom",
    subject: "Sujet",
    message: "Ecrivez votre message ici...",
    submit: "Envoyer",
  },
};
