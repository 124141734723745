import { FormattedMessage } from "react-intl";
import "../../../css/visibilityAnimation.css"

const JobsContainer = ({isVisible}: any) => {
  return (
    <div 
    className={` lg:w-[45%] flex flex-col bg-[#122747] p-4 border rounded-xl border-[#8695aa] w-[92%] lg:min-w-[380px]  ${isVisible ?  "animation_jobs" : "scale-0"}`}>
      <h2 className="lg:text-4xl text-[#64ffda] lg:self-start self-center font-semibold md:font-normal text-xl ">• <FormattedMessage id="internship"/></h2>
      <div className="text-[#ccd6f6] lg:text-xl mt-8 flex flex-col text-lg items-center justify-center space-y-2">
        <span className="text-[#64ffda] lg:self-end">2022 - 6 <FormattedMessage id="months"/></span>
        <span className="lg:self-end"><span className="font-semibold">3WA</span> , Toulouse - <FormattedMessage id="devops_engineer"/></span>
        <span className="text-[#64ffda] lg:self-start">2021 - 4 <FormattedMessage id="months"/></span>
        <span className="lg:self-start"><span className="font-semibold">3WA</span> , Toulouse - <FormattedMessage id="devops_engineer"/></span>
        <span className="text-[#64ffda] lg:self-end">2020 - 4 <FormattedMessage id="months"/></span>
        <span className="lg:self-end"><span className="font-semibold">EDF</span> , Lyon - <FormattedMessage id="devops_test"/></span>
      </div>
    </div>
  );
};

export default JobsContainer;
