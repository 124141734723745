import React from "react";
import { DiCss3, DiJavascript1, DiReact, DiResponsive } from "react-icons/di";
import { SiNextdotjs, SiTailwindcss, SiTypescript } from "react-icons/si";
import { AiFillHtml5 } from "react-icons/ai";
import { BsFillBootstrapFill } from "react-icons/bs";
import CardElement from "./CardElement";

const CardFrontend = () => {
  return (
    <div className="cardSkill">
      <div className="first_row">
        <CardElement name="TypeScript" icon={<SiTypescript size={90} />} />
        <CardElement name="React.JS" icon={<DiReact size={90} />} />
        <CardElement name="Next.JS" icon={<SiNextdotjs size={90} />} />
      </div>
      <div className="second_row">
        <CardElement name="HTML5" icon={<AiFillHtml5 size={90} />} />
        <CardElement name="Responsive" icon={<DiResponsive size={90} />} />
        <CardElement name="CSS3" icon={<DiCss3 size={90} />} />
      </div>
      <div className="third_row">
        <CardElement name="TailwindCSS" icon={<SiTailwindcss size={90} />} />
        <CardElement name="JavaScript" icon={<DiJavascript1 size={90} />} />
        <CardElement name="Bootstrap" icon={<BsFillBootstrapFill size={90} />} />
      </div>
      <div className="skillText">Frontend</div>
    </div>
  );
};

export default CardFrontend;
