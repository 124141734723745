import React from "react";
import { DiMongodb, DiPostgresql } from "react-icons/di";
import { SiFirebase, SiMysql} from "react-icons/si";
import { FaNode, FaPython } from "react-icons/fa";
import CardElement from "./CardElement";

const CardBackend = () => {
  return (
    <div className="cardSkill">
      <div className="first_row">
        <CardElement name="MongoDB" icon={<DiMongodb size={90} />} />
        <CardElement name="Express.JS" icon={<FaNode size={90} />} />
      </div>
      <div className="second_row">
        <CardElement name="Firebase" icon={<SiFirebase size={90} />} />
        <CardElement name="Python/Flask" icon={<FaPython size={90} />} />
      </div>
      <div className="third_row">
        <CardElement name="PostgreSQL" icon={<DiPostgresql size={90} />} />
        <CardElement name="MySQL" icon={<SiMysql size={90} />} />
      </div>
     <div className="skillText">Backend</div>
    </div>

  );
};

export default CardBackend;
