import { FormattedMessage } from "react-intl";
import "../../../css/visibilityAnimation.css";

const CursusContainer = ({isVisible}: any) => {
  console.log(isVisible);
  return (
    <div
      className={`flex flex-col lg:w-[47%] bg-[#122747] p-4 shadow-2xl border border-[#8695aa] rounded-xl w-[92%] lg:min-w-[380px] ${
        isVisible ? "animation_cursus" : "scale-0"
      }`}
    >
      <h2 className="lg:text-4xl text-[#64ffda] w-fit text-xl font-semibold md:font-normal self-center lg:self-start">
        {" "}
        • <FormattedMessage id="background" />
      </h2>
      <p className="text-[#ccd6f6] lg:text-2xl lg:mt-4 mt-2 text-lg lg:text-start text-justify">
        <FormattedMessage id="background_text"/>
        {" "}
        <span
          className="text-[#64ffda] cursor-pointer hover:border-b border-[#64ffda]"
          onClick={() =>
            window.open(
              "https://www.epitech.eu/fr/ecole-informatique-toulouse/"
            )
          }
        >
          Epitech Toulouse
        </span>{" "}
        <FormattedMessage id="background_text2"/>
        <br /> <FormattedMessage id="background_text3" /> <span
          className="text-[#64ffda] cursor-pointer hover:border-b border-[#64ffda]"
          onClick={() =>
            window.open(
              "https://www.csulb.edu/"
            )
          }
        >
          Long Beach University
        </span>{" "} <FormattedMessage id="background_text4"/>
      </p>
    </div>
  );
};

export default CursusContainer;

//https://www.csulb.edu/
