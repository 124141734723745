import React, { useRef } from "react";
import LeftContainerProject from "./LeftContainerProject";
import RightContainerProject from "./RightContainerProject";
import "../../../css/animation.css";

import "../../../css/visibilityAnimation.css";
import { FormattedMessage } from "react-intl";

const ProjectSection = ({ sectionRef }: any) => {
  const mainRef = useRef(null);

  return (
    <div
      ref={sectionRef}
      className="snap-start shrink-0 bg-[#0a192f] w-[105%] md:w-full md:px-44  h-fit"
    >
      <div className="md:h-48 h-32 w-full bg-[#0a192f] md:pt-16 pt-8 flex justify-center md:items-center items-end">
        <span className="text-[#64ffda] md:text-3xl text-xl font-bold">
          <FormattedMessage id="project" />
        </span>
      </div>
      <div ref={mainRef} className={`flex flex-col md:space-y-28 space-y-10`}>
        <LeftContainerProject
          title="Netfake"
          description={<FormattedMessage id="Netfake_description" />}
          tags={["Next.JS", "MongoDB", "Tailwind", "Vercel", "O'Auth"]}
          githubLink="https://github.com/Nolann-epi/Netfake"
          websiteLink="https://netfake-blue.vercel.app/"
          thumbnail="bg-[url('/src/assets/netfake_1.png')]"
        />
        <RightContainerProject
          title="E-CELL"
          description={<FormattedMessage id="Ecell_description" />}
          tags={["Next.JS", "MongoDB", "Tailwind", "Vercel", "PrismaDB"]}
          githubLink="https://github.com/Nolann-epi/e-cell/"
          websiteLink="https://e-cell-theta.vercel.app/cart"
          thumbnail="bg-[url('/src/assets/Ecell.png')] bg-contain"
        />
        <LeftContainerProject
          title="Chesser"
          description={<FormattedMessage id="chesser_description" />}
          tags={["React", "Javascript", "Tailwind", "Typescript", "Algorythm"]}
          githubLink="https://github.com/Nolann-epi/Chesser/"
          websiteLink="https://chesser-kappa.vercel.app/"
          thumbnail="bg-[url('/src/assets/chesser.png')] bg-contain"
        />
      </div>
      <div className="h-40 md:h-56"></div>
    </div>
  );
};

export default ProjectSection;
