import { useRef } from "react";
import { useIsVisible } from "../../../Hooks/VisibilityHook";
import ContactForm from "./ContactForm";
import ContactInformation from "./ContactInformation";

const ContactSection = ({ sectionRef }: any) => {
  const mainRef = useRef(null);
  const isVisible = useIsVisible(mainRef);
  return (
    <div
      ref={sectionRef}
      className={'snap-start shrink-0 w-[100%] lg:w-full lg:px-44  lg:mb-0 h-screen lg:h-screen text-2xl bg-[#0a192f] flex items-center justify-center'}
    >
      <div ref={mainRef} className="flex justify-center items-center lg:flex-row lg:space-x-20 flex-col-reverse mt-10 lg:mt-0 ">
        <ContactForm isVisible={isVisible}/>
        <ContactInformation isVisible={isVisible}/>
      </div>
      
    </div>
  );
};

export default ContactSection;
