import { BiPhoneCall } from "react-icons/bi";
import { FiMail } from "react-icons/fi";
import { GoLocation } from "react-icons/go";
import { FormattedMessage } from "react-intl";

const ContactInformation = ({isVisible}: any) => {

  return (
    <div>
      <div className={` flex flex-col space-y-6 cursor-default bg-[#122747] p-4 rounded-2xl border-[#64ffda] border lg:ml-20 ${isVisible ?  "animation_contact" : "translate-y-[1000px]"}`}>
          <div className="flex justify-center lg:text-4xl  text-2xl pb-2 text-[#64ffda] sd:text-2xl font-bold">
            <FormattedMessage id="contact_title"/>
          </div>
          <div className="flex flex-row space-x-8 items-center text-[#ccd6f6] lg:pt-2">
            <BiPhoneCall className="text-[#64ffda] md:text-3xl text-xl" />
            <div className="text-lg lg:text-2xl">+33 6 02 25 28 39</div>
          </div>
          <div className="flex flex-row space-x-8 items-center text-[#ccd6f6]">
            <FiMail className="text-[#64ffda] md:text-3xl text-xl" />
            <div className="text-lg lg:text-2xl">nolann.holvoet@epitech.eu</div>
          </div>
          <div className="flex flex-row space-x-8 items-center text-[#ccd6f6]">
            <GoLocation className="text-[#64ffda] md:text-3xl text-xl" />
            <div className="text-lg lg:text-2xl">France & Remote</div>
          </div>
        </div>
    </div>
  );
};

export default ContactInformation;
