import React from "react";
import { AiFillGithub, AiFillGitlab, AiFillHtml5 } from "react-icons/ai";
import { BsFillBootstrapFill } from "react-icons/bs";
import {
  DiCss3,
  DiJavascript1,
  DiMongodb,
  DiPostgresql,
  DiReact,
  DiResponsive,
} from "react-icons/di";
import { FaNode, FaPython } from "react-icons/fa";
import { FiFigma } from "react-icons/fi";
import { IoInfiniteSharp } from "react-icons/io5";
import {
  SiDocker,
  SiFirebase,
  SiGnubash,
  SiJira,
  SiMysql,
  SiNextdotjs,
  SiPostman,
  SiTailwindcss,
  SiTypescript,
} from "react-icons/si";
import "../../../css/visibilityAnimation.css";

const SkillContainer = ({ isVisible }: any) => {
  return (
    <div
      className={`lg:w-[40%] flex flex-col bg-[#122747] p-4 border rounded-xl border-[#8695aa] w-[85%] lg:hidden mr-5`}
    >
      <h2 className="lg:text-4xl text-[#64ffda] lg:self-end self-center font-semibold text-xl ">
        Stacks
      </h2>
      <div className="text-[#ccd6f6] lg:text-xl mt-4 flex flex-col text-lg items-center justify-center space-y-8">
        <div className="text-[#64ffda] items-center flex flex-col text-center w-full ">
          <span className="self-start">Backend</span>
          <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
            <FaNode size={30} />
            <DiMongodb size={30} />
            <SiFirebase size={30} />
          </div>

          <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
          <FaPython size={30} />
            <DiPostgresql size={30} />
            <SiMysql size={30} />
            </div>
        </div>
        <div className="text-[#64ffda] flex flex-col text-center w-full pt-5">
          <span className="self-end">Tools</span>

          <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
            <AiFillGitlab size={30} />
            <FiFigma size={30} />
            <AiFillGithub size={30} />
            <SiGnubash size={30} />
          </div>
          <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
            <SiDocker size={30} />
            <SiPostman size={30} />
            <SiJira size={30} />
            <IoInfiniteSharp size={30} />
          </div>
        </div>
        <div className="text-[#64ffda] flex flex-col text-center w-full pt-5">
          <span className="self-start">Frontend</span>

          <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
            <SiTypescript size={30} />
            <DiReact size={30} />
            <SiNextdotjs size={30} />
            <BsFillBootstrapFill size={30} />
            <AiFillHtml5 size={30} />
          </div>
            <div className="text-[#ccd6f6] flex flex-row w-full justify-evenly pt-5">
            <DiResponsive size={30} />
            <DiCss3 size={30} />
            <SiTailwindcss size={30} />
            <DiJavascript1 size={30} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SkillContainer;
