import { useRef } from "react";
import { useIsVisible } from "../../../Hooks/VisibilityHook";
import "../../../css/visibilityAnimation.css";
import { FormattedMessage } from "react-intl";

const HomeSection = ({ sectionRef }: any) => {
  const textVisibility = useRef(null);
  const isVisible = useIsVisible(textVisibility);

  return (
    <div
      ref={sectionRef}
      className="snap-start shrink-0 bg-[#0a192f] flex w-[100%] h-screen  lg:w-full   lg:px-44 items-center "
    >
      <div ref={textVisibility} className=" w-[100%] md:w-full  text-2xl text-[#ccd6f6] md:text-4xl text-center lg:text-6xl items-center flex md:flex-row md:space-x-24 flex-col-reverse justify-center">
        <div className="flex flex-col  space-y-5 px-2 pt-20">
          <div className={`${isVisible ? "animation_main" : "-translate-x-[1100px]"}`}>
          <FormattedMessage id="introduction" /> <span className="text-[#64ffda]">Nolann Holvoet</span>
          </div>
          <div className={`${isVisible ? "animation_main_span" : "-translate-x-[1200px]"}`}>
          <FormattedMessage id="job" />
          </div>
        </div>
        <div className={`h-72 w-72 min-w-[16rem] bg-profile_1  bg-cover text-white rounded-md transition border-[#64ffda] border-4 ${isVisible? "animation_profile_pic": "translate-x-[1000px]"}`}>
            <div className="w-full h-full bg-profile_2 bg-cover hover:opacity-0" />
        </div>
      </div>
    
    </div>
  );
};

export default HomeSection;
