import React from "react";
import { BiCodeAlt } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

interface ButtonsProjectProps {
  side: "left" | "right";
  github: string;
  demo: string;
}

const ButtonsProject = (props: ButtonsProjectProps) => {
  const position =
    props.side === "left" ? "self-end mr-10" : "self-start ml-10";
  console.log(props.demo);
  console.log(props.github);

  return (
    <div className={`flex flex-row ${position} space-x-3 mt-8`}>
      <div
        onClick={() => window.open(props.github)}
        className="flex flex-row items-center space-x-1 text-[#64ffda] border-2 border-[#64ffda] p-1 rounded-xl hover:scale-105 cursor-pointer hover:bg-[#64ffda] hover:text-[#0a192f]"
      >
        <BiCodeAlt size={20} />
        <span className="text-lg">Code</span>
      </div>
      <div
        onClick={() => window.open(props.demo)}
        className="flex flex-row items-center space-x-1 text-[#64ffda] border-2 border-[#64ffda] p-1 rounded-xl hover:scale-105 cursor-pointer hover:bg-[#64ffda] hover:text-[#0a192f]"
      >
        <BsEyeFill size={20} />
        <span className="text-lg">
          <FormattedMessage id="project_button_demo" />
        </span>
      </div>
    </div>
  );
};

export default ButtonsProject;
