import { BrowserRouter, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import NotFound404 from "./pages/NotFound404";
import { IntlProvider } from "react-intl";
import { LOCALES } from "./i18n/locales";
import { messages } from "./i18n/messages";
import { useState } from "react";

const App = () => {
  const language = LOCALES.ENGLISH;
  const [currentLanguage, setLanguage] = useState(language);

  return (
    <IntlProvider
      messages={messages[currentLanguage]}
      locale={currentLanguage}
      defaultLocale={LOCALES.ENGLISH}
    >
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Home currentLanguage={currentLanguage} setLanguage={setLanguage}/>} />
          <Route path="*" element={<NotFound404 />} />
        </Routes>
      </BrowserRouter>
    </IntlProvider>
  );
};

export default App;
