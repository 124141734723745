import React, { useRef } from "react";
import { useIsVisible } from "../../../Hooks/VisibilityHook";
import JobsContainer from "./JobsContainer";
import "../../../css/visibilityAnimation.css";
import CursusContainer from "./CursusContainer";
import AboutMeContainer from "./AboutMeContainer";
import StrenghtContainer from "./StrenghtContainer";

const AboutSection = ({ sectionRef }: any) => {
  const mainRef = useRef(null);
  const secondRef = useRef(null);
  const isVisible = useIsVisible(mainRef);
  const isVisibleSecond = useIsVisible(secondRef);
  return (
    <div
      ref={sectionRef}
      className="snap-start shrink-0 bg-[#0a192f] flex  w-[100%] lg:w-full md:px-24 lg:px-44 h-fit md:overflow-x-hidden flex-col pt-32 lg:space-y-0 space-y-10"
    >
      <div ref={mainRef} className={`flex md:flex-row flex-col justify-center md:justify-evenly items-center space-y-10 md:space-y-0`}>
        <CursusContainer isVisible={isVisible}/>
        <AboutMeContainer isVisible={isVisible}/>
      </div>
      <div ref={secondRef} className="flex md:flex-row flex-col justify-center md:justify-evenly items-center space-y-10 md:space-y-0 md:pt-20">
        <StrenghtContainer isVisible={isVisibleSecond}/>
        <JobsContainer isVisible={isVisibleSecond}/>
      </div>
      <div className="flex md:h-6  h-28">

      </div>
    </div>
  );
};

export default AboutSection;
