import React, { useRef } from "react";
import Navbar from "../Components/Navbar/Navbar";
import AboutSection from "../Components/Section/About/AboutSection";
import ContactSection from "../Components/Section/Contact/ContactSection";
import HomeSection from "../Components/Section/Home/HomeSection";
import ProjectSection from "../Components/Section/Project/ProjectSection";
import StackSection from "../Components/Section/Stack/StackSection";
import SideBarButtons from "../Components/SideBar/SideBarButtons";

interface HomeProps {
  currentLanguage: string;
  setLanguage: (language: string) => void;
}


const Home = (props: HomeProps) => {
  const refList = {
    Home: useRef(null),
    About: useRef(null),
    Stacks: useRef(null),
    Projects: useRef(null),
    Contact: useRef(null),
  };

  return (
    <div className=" bg-[#0a192f] w-screen h-screen">
      <div className="flex flex-col snap-y snap-mandatory h-screen w-screen overflow-y-auto  overflow-x-hidden scrollbar-hide cursor-default">
        <HomeSection sectionRef={refList.Home} />
        <AboutSection sectionRef={refList.About} />
        <StackSection sectionRef={refList.Stacks} />
        <ProjectSection sectionRef={refList.Projects} />
        <ContactSection sectionRef={refList.Contact} />
      </div>
      <Navbar refList={refList} currentLanguage={props.currentLanguage} setLanguage={props.setLanguage}/>
      <SideBarButtons />
    </div>
  );
};

export default Home;