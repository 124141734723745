import React from "react";
import ButtonsProject from "./ButtonsProject";
import MobileContainer from "./MobileContainer";
import { FormattedMessage } from "react-intl";

interface LeftContainerProjectProps {
  title: string;
  description: any;
  tags: string[];
  githubLink: string;
  websiteLink: string;
  thumbnail: string;
}
//'/src/assets/netfake_1.png'

const LeftContainerProject = (props: LeftContainerProjectProps) => {
  return (
    <>
      <div className="h-80 w-full md:flex flex-row px-36 hidden min-w-fit">
        <div className={`w-1/2 h-full bg-cover bg-center ${props.thumbnail}`}>
          <div className="over_card"></div>
        </div>
        <div className="w-1/2 h-full bg-[#0a192f] flex flex-col">
          <span className="text-xl self-end mr-10 text-[#64ffda]">
            <FormattedMessage id="project_subtitle" />
          </span>
          <span className="text-2xl self-end mr-10 text-[#ccd6f6]">
            {props.title}
          </span>
          <div className="h-1/3 bg-[#112d58] mt-10 text-xl text-[#a8b2d1] w-[105%] -translate-x-[4.8%] flex px-3 py-3 break-words justify-center overflow-hidden shadow-2xl">
            {props.description}
          </div>
          <div className="flex flex-row text-xl self-center space-x-6 ml-10  mt-4 min-w-[1/2] w-[92%]">
            {props.tags.map((tag) => (
              <span className="text-[#ccd6f6] hover:-translate-y-1">{tag}</span>
            ))}
          </div>
          <ButtonsProject
            side={"left"}
            github={props.githubLink}
            demo={props.websiteLink}
          />
        </div>
      </div>
      <MobileContainer
        title={props.title}
        description={props.description}
        tags={props.tags}
        githubLink={props.githubLink}
        websiteLink={props.websiteLink}
        thumbnail={props.thumbnail}
      />
    </>
  );
};

export default LeftContainerProject;
