import React from "react";

interface ElementProps {
  name: string;
  icon: JSX.Element;
}

const CardElement = (props: ElementProps) => {
  return (
    <div className="flip-card">
      <div className="flip-card-inner">
        <div className="flip-card-front">
          <div className="element">{props.icon}</div>
        </div>
        <div className="flip-card-back">
          <p className="text-base text-black cursor-default font-semibold"> {props.name}</p>
        </div>
      </div>
    </div>
  );
};

export default CardElement;
