import React, { useState } from "react";
import { AiOutlineLinkedin, AiFillGithub } from "react-icons/ai";
import { FiMail } from "react-icons/fi";
import PopupClipboard from "./PopupClipboard";

const SideBarButtons = () => {
  const [emailCopied, setEmailCopied] = useState<boolean>(false);

  return (
    <>
      <div className="md:w-28 lg:w-44  bg-transparent h-80 fixed bottom-0 hidden md:flex md:flex-col justify-end items-center space-y-8 ">
        <AiFillGithub
          size={60}
          className="text-[#ccd6f6] hover:text-[#55dbbf] cursor-pointer"
          onClick={() => window.open("https://github.com/Nolann-epi")}
        />
        <AiOutlineLinkedin
          size={60}
          className="text-[#ccd6f6] hover:text-[#55dbbf] cursor-pointer"
          onClick={() =>
            window.open("https://www.linkedin.com/in/nolann-holvoet-23b9a91ab/")
          }
        />
        <FiMail
          size={60}
          className="text-[#ccd6f6] hover:text-[#55dbbf] cursor-pointer"
          onClick={() => {
            navigator.clipboard.writeText("nolann.holvoet@epitech.eu");
            setEmailCopied(true);
          }}
        />

        <p className=" h-56 border-[#64ffda] border-l-4 rounded-full"></p>
      </div>
      <PopupClipboard setShow={setEmailCopied} show={emailCopied} />
    </>
  );
};

export default SideBarButtons;