import  { useRef } from "react";
import { useIsVisible } from "../../../Hooks/VisibilityHook";
import "../../../css/visibilityAnimation.css"
import CardBackend from "./CardBackend";
import CardTools from "./CardTools";
import CardFrontend from "./CardFrontend";
import SkillContainer from "./SkillContainer";

const StackSection = ({ sectionRef }: any) => {
  const cardRef = useRef(null);
  const isCardVisible = useIsVisible(cardRef);

  return (
    <div
      ref={sectionRef}
      className="snap-start shrink-0 bg-[#0a192f] flex w-[105%] h-screen  lg:w-full lg:flex-col  lg:px-44 justify-center space-y-16 items-center flex-col-reverse"
    >   
       <div ref={cardRef} className="w-auto border-[#ccd6f6] md:text-4xl text-[#64ffda] text-2xl  md:flex  hidden font-bold">
        Stacks
      </div>
      <div   className={`lg:flex flex-row text-[#64ffda] justify-center w-full space-x-24 md:pt-10 pt-0 hidden ${isCardVisible ?  "animation_cards" : "translate-x-[1700px]"}`}>
        <CardBackend />
        <CardTools />
        <CardFrontend />
      </div>
      <SkillContainer  />
    </div>
  );
};

export default StackSection;
