import "../../../css/visibilityAnimation.css";
import { useState } from "react";
import { handleMailSender } from "./MailSender";
import { FormattedMessage } from "react-intl";

const ContactForm = ({ isVisible }: any) => {
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [returnMessage, setReturnMessage] = useState("");

  const handleSubmit = async (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.preventDefault();
    if (name === "" || email === "" || subject === "" || message === "") {
      setReturnMessage("Please fill in all the fields");
      return;
    }
    const status = await handleMailSender({ name, email, subject, message });
    if (status === 200) {
      setReturnMessage("Your message has been sent successfully");
    } else {
      setReturnMessage("An error has occured");
    }
  };
   return (
    <div
      className={`pr-3 pl-2 py-2 pt-6 lg:border-r-2 border-[#64ffda] lg:pr-40 ${
        isVisible ? "animation_form" : "translate-y-[1000px]"
      }`}
    >
      <div className="flex justify-center lg:text-4xl  text-2xl lg:pb-6 pb-2 text-[#64ffda] cursor-default font-bold">
        <FormattedMessage id="contact_form" />
      </div>
      <div className="flex flex-row  mt-3 space-x-2 text-2xl">
        <input
          type="text"
          required
          name="name"
          id="name"
          placeholder="Name"
          onChange={(e) => setName(e.target.value)}
          className="px-1 py-1 block p-2.5 w-full text-lg text-[#ccd6f6] placeholder:opacity-50  rounded-lg border outline-none focus:ring-[#64ffda] focus:border-[#64ffda] bg-gray-700 border-gray-600 placeholder-[#ccd6f6]"
        />
        <input
          type="text"
          required
          id="subject"
          placeholder="Subject"
          onChange={(e) => setSubject(e.target.value)}
          className="px-1 py-1 block p-2.5 w-full text-lg text-[#ccd6f6] placeholder:opacity-50  rounded-lg border outline-none focus:ring-[#64ffda] focus:border-[#64ffda] bg-gray-700 border-gray-600 placeholder-[#ccd6f6]"
        />
      </div>
      <div className="flex flex-col w-full  mr-auto mt-3 space-y-3">
        <input
          type="email"
          required
          id="email"
          placeholder="Email"
          onChange={(e) => setEmail(e.target.value)}
          className="px-1 py-1 block p-2.5 w-full text-lg text-[#ccd6f6] placeholder:opacity-50  rounded-lg border outline-none focus:ring-[#64ffda] focus:border-[#64ffda] bg-gray-700 border-gray-600 placeholder-[#ccd6f6]"
        />
        <textarea
          name="message"
          id="message"
          required
          placeholder="Type your message here..."
          onChange={(e) => setMessage(e.target.value)}
          className="px-2 py-2 block p-2.5 lg:h-60 h-20 w-full text-lg text-[#ccd6f6] placeholder:opacity-50  mb-2 rounded-lg border outline-none focus:ring-[#64ffda] focus:border-[#64ffda] bg-gray-700 border-gray-600 placeholder-[#ccd6f6]"
        />
        <div className="flex justify-center lg:pt-6 pt-3">
          <div
            onClick={(event) => handleSubmit(event)}
            className="md:px-3  px-2 w-fit text-[#0a192f] md:py-2 py-1 md:text-lg text-base bg-[#64ffda] rounded-sm cursor-pointer hover:scale-110"
          >
            <FormattedMessage id="submit" />
          </div>
        </div>
        {returnMessage &&
          (returnMessage === "Your message has been sent successfully" ? (
            <div className="flex justify-center lg:pt-6 text-center">
              <div className="text-green-500 text-sm md:text-xl">{returnMessage}</div>
            </div>
          ) : (
            <div className="flex justify-center  lg:pt-6 text-center">
              <div className="text-red-500 text-sm md:text-xl">{returnMessage}</div>
            </div>
          ))}
      </div>
    </div>
  );
};

export default ContactForm;
