import emailjs from "@emailjs/browser";

export interface MailSenderProps {
  name: string;
  email: string;
  subject: string;
  message: string;
}

export const handleMailSender = async (props: MailSenderProps) => { // Add the async keyword here
    try {
      const response = await emailjs.send( // Use the await keyword here
        "Portfolio",
        "template_portfolio",
        {
          name: props.name,
          email: props.email,
          subject: props.subject,
          message: props.message,
        },
        "1NQtVBxE3LuK3yyFN"
      );
      return response.status;
    } catch (error) {
      return error;
    }
  };