import React from "react";
import { SiGnubash, SiJira, SiPostman } from "react-icons/si";
import { AiFillGithub, AiFillGitlab } from "react-icons/ai";
import { FaDocker } from "react-icons/fa";
import { FiFigma } from "react-icons/fi";
import { IoInfiniteSharp } from "react-icons/io5";
import CardElement from "./CardElement";

const CardTools = () => {
  return ( 
    <div className="cardSkill">
      <div className="first_row">
        <CardElement name="GitLab" icon={<AiFillGitlab size={90} />} />
        <CardElement name="Figma" icon={<FiFigma size={90} />} />
        <CardElement name="Jira" icon={<SiJira size={90} />} />
      </div>
      <div className="second_row">
        <CardElement name="GitHub" icon={<AiFillGithub size={90} />} />
        <CardElement name="Postman" icon={<SiPostman size={90} />} />
      </div>
      <div className="third_row">
        <CardElement name="Bash" icon={<SiGnubash size={90} />} />
        <CardElement name="Docker" icon={<FaDocker size={90} />} />
        <CardElement name="Agile Method" icon={<IoInfiniteSharp size={90} />} />
      </div>
      <div className="skillText">Tools</div>
    </div>
  );
};

export default CardTools;
