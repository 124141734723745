import React from "react";
import { useNavigate } from "react-router-dom";

const NotFound404 = () => {

  const navigate = useNavigate();

  return (
    <div className="w-screen h-screen bg-[#0a192f]">
      <div className="flex flex-col h-96 w-screen justify-center items-center">
        <h1 className="text-5xl text-center text-[#64ffda]">Error 404</h1>
        <h2 className="text-3xl mt-10 text-center text-[#55dbbf]">
          {" "}
          Page not found...
        </h2>
        <h2 className="text-3xl text-center text-[#55dbbf] border-2 border-[#55dbbf] px-2 py-1 mt-36 hover:bg-[#55dbbf] hover:text-[#0a192f] cursor-pointer rounded-2xl" 
            onClick={() => navigate("/") }>Go back to Home</h2>
      </div>
    </div>
  );
};
export default NotFound404;
