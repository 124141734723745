import React from "react";
import { BiCodeAlt } from "react-icons/bi";
import { BsEyeFill } from "react-icons/bs";
import { FormattedMessage } from "react-intl";

interface MobileContainerProps {
  title: string;
  description: string;
  tags: string[];
  githubLink: string;
  websiteLink: string;
  thumbnail: string;
}

const MobileContainer = (props: MobileContainerProps) => {
  return (
    <div className="h-90 w-[90%] md:hidden flex flex-col items-center space-y-5 border-2 rounded-md border-[#64ffda] m-3 p-1 pt-4">
      <div className="w-full text-center text-[#64ffda]">{props.title}</div>
      <div className={`w-[90%] h-32 bg-cover ${props.thumbnail}`}></div>
      <div className="w-[90%] h-24 bg-[#112d58] text-[#a8b2d1] overflow-hidden text-sm text-center p-2 ">
        {props.description}
      </div>
      <div className="flex flex-row space-x-2 w-full overflow-hidden justify-center">
        {props.tags.map((tag) => (
          <span className="text-[#ccd6f6]">{tag}</span>
        ))}
      </div>
      <div className="flex flex-row justify-center space-x-8 pb-3">
        <div
          onClick={() => window.open(props.githubLink, "_blank")}
          className="flex flex-row items-center space-x-1 text-[#64ffda] border-2 border-[#64ffda] p-1 rounded-xl hover:scale-105 cursor-pointer hover:bg-[#64ffda] hover:text-[#0a192f]"
        >
          <BiCodeAlt size={20} />
          <span className="text-base">Code</span>
        </div>
        <div
          onClick={() => window.open(props.websiteLink, "_blank")}
          className="flex flex-row items-center space-x-1 text-[#64ffda] border-2 border-[#64ffda] p-1 rounded-xl hover:scale-105 cursor-pointer hover:bg-[#64ffda] hover:text-[#0a192f]"
        >
          <BsEyeFill size={20} />
          <span className="text-base">
            <FormattedMessage id="project_button_demo" />
          </span>
        </div>
      </div>
    </div>
  );
};

export default MobileContainer;
